import { useEffect, useState } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import { ModalApplicant } from "./Modal/ModalApplicant";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Filter from "./Filters";
import AlertDialog from "../AlertDialog";
import {
  Jobs,
  resetFilters,
  setDialog,
  setFilters,
  setFiltersDialog,
  setJobApplicantDialog,
} from "../../app/slices/Job/jobsSlice";
import { useDebounce } from "use-debounce";
import { useDispatch, useSelector } from "react-redux";

export const Table = () => {
  const dispatch = useDispatch();
  const {
    jobs: { data, loading, filters, total },
    jobApplicant: { dialog },
    manageJob,
  } = useSelector((state) => state.jobs);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);

  const colorDate = (myData) => {
    const currentDate = new Date(); // Current date
    const providedDate = new Date(myData);

    // Compare dates
    if (currentDate > providedDate) {
      return "red";
    } else {
      return "green";
    }
  };

  const convertToInt = (string) => {
    var newInt = parseFloat(string.replace(/,/g, ""));
    return newInt;
  };

  const handleEdit = (data) => {
    dispatch(
      setDialog({
        id: data.id,
        company_id: data.company,
        employer_name: data.employer_name,
        job_title_id: data.job_title,
        other_job_title: data.other_job_title,
        work_days: data.work_days,
        work_type_id: data.work_type,
        description: data.description,
        due_to: data.due_to,
        full_address: data.full_address,
        education_degree_id: data.education_degree,
        gender: data.gender,
        salary_from: convertToInt(data.salary_from),
        salary_to: convertToInt(data.salary_to),
        province_id: data.province,
        city_id: data.city,
        currency: data.currency,
        is_active: data.is_active,
      })
    );
  };
  const columns = [
    {
      field: "name_en",
      headerName: "Job Title",
      headerClassName: "employer-grid--header",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row?.job_title?.name_en}
              variant="subtitle1"
              fontWeight="bold"
            />
            <ColumnText
              text={params.row?.job_title?.name_ar}
              name_ar="caption"
              color={"gray"}
            />
          </Box>
        );
      },
    },
    {
      field: "company_name",
      headerName: "Company Name",
      headerClassName: "employer-grid--header",
      flex: 1.4,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Avatar src={params.row?.company?.profile_pic} sizes={"small"} />
            <Box display={"flex"} flexDirection={"column"}>
              <ColumnText
                text={
                  params.row.company?.company_name || params.row.employer_name
                }
                variant="subtitle2"
                fontWeight="bold"
              />
            </Box>
          </Box>
        );
      },
    },
    {
      field: "eduaction_degree",
      headerName: "Eduaction Degree",
      flex: 1,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            text={
              params.row?.education_degree?.name_en &&
              params.row?.education_degree?.name_en
            }
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            text={`${params.row?.city?.province?.name_en} - ${params.row?.city?.name_en}`}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "views_count",
      headerName: "Views & Appliers",
      flex: 1,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box
            key={params.row?.id}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <ColumnText
              text={params.row?.views_count}
              variant="subtitle2"
              fontWeight="bold"
            />
            &nbsp;
            <VisibilityIcon color="warning" />
            &nbsp;
            <Typography variant="subtitle2">|</Typography>
            &nbsp;
            <ColumnText
              text={params.row?.appliers}
              variant="subtitle2"
              fontWeight="bold"
            />
            &nbsp;
            <DoneAllIcon color="success" />
          </Box>
        );
      },
    },
    {
      field: "salary",
      headerName: "Salary",
      flex: 1,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={`${params.row?.salary_from} - ${params.row?.salary_to}`}
              variant="subtitle2"
              fontWeight="bold"
            />
            <ColumnText
              text={params.row?.currency}
              name_ar="currency"
              variant="body"
              color={"gray"}
            />
          </Box>
        );
      },
    },
    {
      field: "expired_date",
      headerName: "Expired Date",
      flex: 0.8,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <ColumnText
            color={colorDate(params.row?.due_to)}
            text={params.row?.due_to?.split(" ")[0]}
            variant="subtitle2"
            fontWeight="bold"
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      sortable: false,
      flex: 1.2,
      headerClassName: "employer-grid--header",

      renderCell: (params) => {
        const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useState(false);
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => handleEdit(params.row)}
              type={"edit"}
            />

            <ColumnButton
              onClick={() => setShowConfirmDeleteModal(true)}
              type={"delete"}
            />
            <ColumnButton
              title="Applicant"
              color={"info"}
              onClick={() =>
                dispatch(
                  setJobApplicantDialog({
                    job_id: params.row.id,
                  })
                )
              }
              icon={<RecentActorsIcon color="info" />}
            />
            <AlertDialog
              open={showConfirmDeleteModal}
              action={() => dispatch(Jobs.deleteJob(params.row.id))}
              handleClose={() => setShowConfirmDeleteModal(false)}
              title="Delete job"
              message={`Are you sure you want to delete (${params.row?.job_title?.name_en} - ${params.row?.job_title?.name_ar})`}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(Jobs.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [filters.skip, filters.take, filters.page, filters.search]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          title="Jobs"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          btn={"Add Job"}
          fetchData={fetchData}
          isFilter={true}
          handleFilter={() => {
            dispatch(setFiltersDialog());
          }}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
          handleAdd={() => dispatch(setDialog())}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(Jobs.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
        {/* Modal */}
        {manageJob.dialog && <ModalInfo />}
        {dialog && <ModalApplicant />}
        <Filter fetchData={fetchData} />
      </Box>
    </>
  );
};

export default Table;
